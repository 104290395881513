<template>
  <Dialog
    v-model="dialogShow"
    :confirm="true"
    :content-style="{ padding: '0' }"
  >
    <div class="game-subscribe__dialog-content">
      <div class="game-subscribe__dialog-content-icon" />
      <div class="game-subscribe__dialog-content-text">
        <span
          class="game-subscribe__dialog-content-text-successful"
        >{{ contentTitle }}</span>
        <span
          class="game-subscribe__dialog-content-text-notify"
        >{{ content }}</span>
      </div>
    </div>
    <template slot="footer">
      <div class="game-subscribe__dialog-button">
        <Button
          :width="111"
          @click="$emit('change', false)"
          type="default"
        >
          {{ leftBtnContent }}
        </Button>
        <Button
          v-if="!isJoinGroup ||( isJoinGroup && Number(groupId))"
          :width="111"
          @click="onOpenNotificationButtonClick"
          type="secondary"
          class="game-subscribe__dialog-button-right"
        >
          {{ rightBtnContent }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>
<script>
import Dialog from '@/ui/dialog/index.vue';
import Button from '@/ui/button/index.vue';
import jsbridge from '@/jsbridge';

export default {
  name: 'GameSubscribeDialog',
  components: {
    Dialog,
    Button,
  },
  model: {
    prop: 'dialogShow',
    event: 'change',
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '新游上线会通知你哟',
    },
    contentTitle: {
      type: String,
      default: '预约成功',
    },
    leftBtnContent: {
      type: String,
      default: '下次再说',
    },
    rightBtnContent: {
      type: String,
      default: '开启消息通知',
    },
    isJoinGroup: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
      default: '',
    },
  },

  methods: {
    onOpenNotificationButtonClick() {
      this.$emit('change', false);
      if (this.isJoinGroup) {
        // 进入圈子
        this.$linkToGroup(this.groupId);
        return;
      }
      jsbridge.device.goToAppSystemSetting();
    },

  },
};
</script>
<style scoped lang="scss">
.game-subscribe__dialog {
  &-content {
    font-size: 14px;
    color: #4c4c4c;
    text-align: center;
    font-weight: 400;
    line-height: 17px;

    &-icon {
      width: 280px;
      height: 156px;
      background-size: 100% 100%;
      background-image: url("./img/game-info-dialog-content-icon.png");
    }
    &-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 24px;
      &-successful {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #303133;
      }
      &-notify {
        text-align: left;
        margin:0 20px;
        margin-top: 16px;
      }
    }
  }
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    &-right{
      margin-left: 20px;
    }
    .ui-button.size-m {
      font-size: 14px;
      height: 38px;
    }
  }
}
</style>
